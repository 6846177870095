import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { chainShortcutHandlers } from '@/src/modules/keyboardShortcuts/utils';
import { isTouchScreen } from '@/src/modules/ui/utils/isTouchScreen';
import { isTruthy } from '@/src/utils/guards';
import isHotkey from 'is-hotkey';
import React from 'react';

interface Options {
  onOpenExternal?: () => void;
  onOpen?: () => void;
  enabled?: boolean;
}

const defaultReturnValue = {
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  isMouseOver: false,
};
const noopHook = () => defaultReturnValue;

/**
 * Hook that handles the keyboard events for the resource preview
 * This is completely disabled when user is on a touch screen
 */
export const useResourceHoverKeyboardEvents = isTouchScreen()
  ? noopHook
  : (options: Options) => {
      const [isMouseOver, setIsMouseOver] = React.useState(false);
      const enabled =
        (options.enabled ?? true) &&
        [options.onOpenExternal, options.onOpen].some(isTruthy) &&
        !isTouchScreen();

      const shortcutsEnabled = isMouseOver && enabled;

      /**
       * make sure to reset the isMouseOver state when enabled sets to false
       */
      React.useEffect(() => {
        if (!enabled) {
          setIsMouseOver(false);
        }
      }, [enabled]);

      useWindowEvent(
        'keydown',
        (e) => {
          chainShortcutHandlers(
            e,
            [
              (e) => {
                if (isHotkey('Enter', e) && options.onOpenExternal) {
                  options.onOpenExternal();
                  return true;
                }
              },
              (e) => {
                if (isHotkey('space', e) && options.onOpen) {
                  options.onOpen();
                  return true;
                }
              },
            ],
            {
              preventDefaultOnHandle: true,
              stopPropagationOnHandle: true,
              stopOnFirstSuccess: true,
            },
          );
        },
        {
          enabled: shortcutsEnabled,
        },
      );

      return {
        onMouseEnter: enabled ? () => setIsMouseOver(true) : undefined,
        onMouseLeave: enabled ? () => setIsMouseOver(false) : undefined,
        isMouseOver: enabled ? isMouseOver : false,
      };
    };
