import { IconLinkArrow } from '@/src/modules/icons';
import { IconSharedWithPublic } from '@/src/modules/resources/components/IconSharedWithPublic';
import { useResourceHoverKeyboardEvents } from '@/src/modules/resources/components/ResourcePreview/hooks/useResourcePreviewKbdEnterOpenExternal';
import { ResourcePreviewComponent } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { PreviewContainer } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/PreviewContainer';
import {
  DESKTOP_CARD_HEIGHT,
  DESKTOP_CARD_WIDTH,
  TAGS_SECTION_HEIGHT,
  TITLE_SECTION_HEIGHT,
} from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { ResourcePreviewContent } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewContent';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { ResourcePreviewPinnedComment } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/ResourcePreviewComment';
import { ResourcePreviewName } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/ResourcePreviewName';
import { ResourcePreviewTags } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/ResourcePreviewTags';
import {
  cssHover,
  cssPreviewShadow,
} from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { useResourcePreviewUiConfigContext } from '@/src/modules/resources/components/ResourcePreview/resourcePreviewUiConfigContext';
import { isNotepadFdoc, isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { LinkExternal } from '@/src/modules/ui/components/Link/LinkExternal';
import { hasHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { SPOTIFY_URL_REGEX } from '@/src/utils/url-regex';
import { useInView } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

type PreviewVariant = 'spotify' | 'notepad' | 'thumbnail';

const getResourcePreviewVariant = (resource: OptimisticDraft<Fdoc>): PreviewVariant | undefined => {
  if (!!(resource.type === 'page' && resource.data.pageUrl.match(SPOTIFY_URL_REGEX))) {
    return 'spotify';
  }

  if (isNotepadFdoc(resource)) {
    return 'notepad';
  }

  if (isStoredFileFdoc(resource) && resource.data.thumbnail?.md) {
    return 'thumbnail';
  }

  return undefined;
};

const COMMENT_SECTION_HEIGHT = 33;

const Inner: ResourcePreviewComponent = ({ resource, children, appearence }) => {
  const tags = resource.personal.tags;
  const comment = resource.commentPinned;

  const previewVariant = getResourcePreviewVariant(resource);

  return (
    <>
      {children}
      {/** floating elements */}

      {/** preview */}
      <ResourcePreviewGridItemContentPreview>
        <ResourcePreviewContent resource={resource} />
      </ResourcePreviewGridItemContentPreview>

      {!appearence?.hideMeta && (
        <ResourcePreviewGridItemMetaContent>
          {!appearence?.hideTitle && (
            <ResourcePreviewName
              spaceTitle={appearence?.showRootInFilename ? resource.listData?.title : undefined}
              resource={resource}
            />
          )}
          {!appearence?.hideComment && (comment || resource.isDirectShared) && (
            <CommentAndShareContainer uncontainedPreview={!previewVariant}>
              {comment && (
                <ResourcePreviewPinnedComment
                  comment={comment}
                  commentsCount={resource.commentCount || 0}
                />
              )}

              {resource.isDirectShared && <IconSharedWithPublic />}
            </CommentAndShareContainer>
          )}
          {!appearence?.hideTags && (
            <ResourcePreviewTags compactAbsolute={previewVariant === 'thumbnail'} tags={tags} />
          )}
        </ResourcePreviewGridItemMetaContent>
      )}
    </>
  );
};

export const ResourcePreviewGridItem: ResourcePreviewComponent = (props) => {
  const {
    resource,
    children,
    colorLabels,
    appearence: appearenceProp,
    onOpen,
    draggableRef,
    onMouseDown,
    className,
    isSelected,
    isSelectable,
    ...rest
  } = props;

  const uiConfigContext = useResourcePreviewUiConfigContext();

  const appearence = {
    ...uiConfigContext?.appearence,
    ...appearenceProp,
  };
  const innerProps = {
    ...rest,
    resource,
    appearence,
  };

  const minusBaseHeight = [
    appearence?.hideTitle ? TITLE_SECTION_HEIGHT : 0,
    appearence?.hideComment ? COMMENT_SECTION_HEIGHT : 0,
    appearence?.hideTags ? TAGS_SECTION_HEIGHT : 0,
  ].reduce((acc, curr) => acc + curr, 0);

  const previewVariant = getResourcePreviewVariant(resource);
  const ref = React.useRef<HTMLDivElement>(null);

  const inView = useInView(ref, { margin: '1000px' });

  const externalLinkRef = React.useRef<HTMLAnchorElement>(null);
  const { isMouseOver, onMouseEnter, onMouseLeave } = useResourceHoverKeyboardEvents({
    onOpenExternal: () => externalLinkRef.current?.click(),
    onOpen,
    enabled: !isSelected && inView,
  });

  return (
    <PreviewContainer {...rest} className={className}>
      {resource.originUrl && (
        <ExternalLinkButton
          variant="bg-primary-reverse"
          as={LinkExternal}
          href={resource.originUrl}
          isVisible={isMouseOver}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={externalLinkRef}
        >
          <IconLinkArrow style={{ width: '.875rem', height: '.875rem' }} />
        </ExternalLinkButton>
      )}

      {/** ^ Container adds css container context */}
      <Wrapper variant={previewVariant} ref={ref} minusBaseHeight={minusBaseHeight}>
        {/** ^ Wrapper controls dimension based in outer size */}
        <InnerLayout
          onClick={onOpen}
          ref={draggableRef}
          onMouseDown={onMouseDown}
          isSelected={isSelected}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-selectable-id={
            resource?.isDraft || resource?.listData?.integration || !isSelectable
              ? undefined
              : resource?.id
          }
        >
          {/** ^ InnerLayout wrapps inner content, hover state on desktop */}
          {inView && <Inner {...innerProps} />}
        </InnerLayout>
      </Wrapper>
    </PreviewContainer>
  );
};

const ExternalLinkButton = styled(ButtonIcon).attrs<{ isVisible: boolean }>({
  size: 'lg',
})`
  border-radius: 1rem;
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  z-index: 20;
  /* border: none; */
  opacity: 0;
  scale: 0.8;
  ${(p) =>
    p.isVisible &&
    css`
      opacity: 1;
      scale: 1;
    `}

  display: none;
  ${hasHover} {
    display: flex;
    &:hover {
      scale: 1.05;
    }
  }
`;

const ResourcePreviewGridItemContentPreview = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  position: relative;

  ${containerPreviewCompact} {
    flex-shrink: 1;
    flex-grow: 1;
    border-radius: 0;
    overflow: hidden;
  }
`;

const InnerLayout = styled(Flex).attrs<{ isSelected?: boolean }>((props) => {
  return {
    direction: 'column',
    gap: 12,
    ...props,
  };
})`
  position: relative;
  cursor: pointer;

  ${(p) =>
    p.isSelected
      ? /** Selected */
        css`
          position: relative;

          &::after {
            content: ' ';
            position: absolute;
            inset: -10px;
            background: rgba(42, 55, 238, 0.2);
            /* border-radius: 22px; */
            border-radius: 1.625rem;
            z-index: 2;
            border: 2px solid rgba(42, 55, 238, 0.4);
            pointer-events: none;
          }
        `
      : css`
          /** HOVER */
          @media (hover: hover) {
            &:before {
              display: block;
              content: '';
              inset: -10px;
              background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.03);
              position: absolute;
              border-radius: 1.625rem;
              opacity: 0;
              transition: 0.1s opacity;
            }
            &:hover:before {
              opacity: 1;
            }
          }
        `}
  /** compact  */


  ${containerPreviewCompact} {
    overflow: hidden;
    flex-grow: 1;
    gap: 0;
    &:before {
      display: none;
    }
  }
`;

const Wrapper = styled(Flex).attrs<{ variant?: PreviewVariant; minusBaseHeight?: number }>(
  (props) => {
    return {
      direction: 'column',
      gap: 12,
      ...props,
    };
  },
)`
  position: relative;
  flex-grow: 1;

  height: ${(p) => `calc(${DESKTOP_CARD_HEIGHT}px - ${p.minusBaseHeight || 0}px)`};
  width: ${DESKTOP_CARD_WIDTH}px;
  --preview-border-color: ${cssVar['color-border-secondary']};
  ${(p) => {
    switch (p.variant) {
      case 'spotify':
        return css`
          --preview-border-color: rgba(30, 215, 96, 0.18);
          --preview-background-color: #f5fff7;
        `;
      case 'notepad':
        return css`
          --preview-background-color: ${cssVar['color-bg-note-primary']};
        `;
    }
  }}

  ${containerPreviewCompact} {
    height: auto;
    width: auto;
    aspect-ratio: ${cssVar['preview-compact-card-aspect-ratio']};
    background: ${cssVar['color-bg-primary']};
    border-radius: 1.125rem;
    overflow: hidden;
    border: 1px solid var(--preview-border-color);
    background: var(--preview-background-color, ${cssVar['color-bg-primary']});

    ${cssPreviewShadow} // hover is handled by this wrapper component when compact only
    ${cssHover}
  }
`;

const ResourcePreviewGridItemMetaContent = styled(Flex).attrs((props) => {
  return {
    direction: 'column',
    alignItems: 'center',
    ...props,
  };
})`
  gap: 0.625rem;
  padding: 0 0.6125rem;
  position: relative;
  ${containerPreviewCompact} {
    align-items: flex-start;
    padding: 0;
    gap: 0;
  }
`;

const CommentAndShareContainer = styled(Flex).attrs<{ uncontainedPreview?: boolean }>({
  alignItems: 'center',
  justifyContent: 'center',
  gap: 'elementsContainer',
})`
  height: ${COMMENT_SECTION_HEIGHT}px;
  ${containerPreviewCompact} {
    order: 4;
    padding: 0.6875rem;
    background: ${cssVar['color-bg-primary']};
    width: 100%;
    height: 38px;
    ${(p) =>
      p.uncontainedPreview &&
      css`
        height: 28.5px;
        padding-top: 0;
      `}
  }
`;
