import NoteStack from '@/src/components/NoteStack/NoteStack';
import { useResponsive } from '@/src/hooks/responsive';
import Modal from '@/src/modules/ui/components/Modal';
import { mediaMobile, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import plur from 'plur';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const NoteStackContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-top: 4rem;

  ${mediaMobile} {
    padding-top: env(safe-area-inset-top);
    gap: 0rem;
    justify-content: flex-end;
    height: 100%;
    & > * {
      max-height: 100%;
    }
    .note-stack-tilt-container {
      max-height: 20dvh;
    }
  }
  &::before,
  &::after {
    content: '';
    flex: 1;
  }
`;

const StyledNoteStack = styled(NoteStack)`
  height: 50dvh;
  width: 50dvh;
  max-height: 260px;
  max-width: 320px;
  ${mediaMobile} {
    height: 180px;
  }
`;

const inAnimationNotestack = keyframes`
  from {
    opacity: 0;
    scale: 0.5;
  }
  to {
    opacity: 1;
    scale: 1;
  }
`;

const outAnimationNotestack = keyframes`
  from {
    opacity: 1;
    scale: 1;
  }
  to {
    opacity: 0;
    scale: 0.5;
  }
`;

const NotestackContent = styled(Modal.Content)`
  left: 2rem;
  width: calc(50% - 4rem);
  top: 50%;
  max-width: none;
  background: transparent;
  border: none;
  backdrop-filter: none;
  box-shadow: none;
  overflow: initial;
  height: 100dvh;
  max-height: none;
  pointer-events: none;
  justify-content: center;

  ${mediaNotMobile} {
    --modal-transform-x: 0%;
  }

  /**
   * 📱 mobile
   */
  ${mediaMobile} {
    padding-top: env(safe-area-inset-top);
    height: 40dvh;
    top: 0;
    transform: none;
    left: 0;
    width: 100%;

    // animation
    opacity: 0;
    scale: 0.5;
    justify-content: flex-end;
    &[data-state='open'] {
      animation: ${inAnimationNotestack} 0.2s ease-out forwards;
      animation-delay: 0.2s;
    }

    &[data-state='closed'] {
      animation: ${outAnimationNotestack} 0.2s ease-out forwards;
    }
  }
`;

const SelectedInfo = styled.div`
  background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.3);
  height: 2.75rem;
  padding: 0 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${cssVar['color-text-primary-reverse']};
  border-radius: 0.875rem;
  font-weight: 500;
  flex-shrink: 0;
  ${mediaMobile} {
    height: 2.25rem;
    font-size: 0.875rem;
    padding: 0 0.5rem;
    margin-top: 1rem;
  }
`;

export const MoveModalNoteStack = React.forwardRef<
  HTMLDivElement,
  { resources: OptimisticDraft<Fdoc>[] }
>(function MoveModalNoteStackComp({ resources }, ref) {
  const { isMobileView } = useResponsive();
  const itemsCount = resources.length;
  return (
    <NotestackContent
      aria-describedby="Preview of the files"
      ref={ref}
      tabIndex={-1}
      onEscapeKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      <NoteStackContentInner>
        <StyledNoteStack
          scale={isMobileView ? 0.6 : 0.9}
          verticalSpread={0.5}
          horizontalSpread={0.8}
          fdocs={resources}
          noTilt={isMobileView}
        />
        <SelectedInfo>
          ({itemsCount} {plur('item', itemsCount)} selected)
        </SelectedInfo>
      </NoteStackContentInner>
    </NotestackContent>
  );
});
