import React from 'react';

interface Event {
  preventDefault?: VoidFunction;
}

const isEvent = (e?: unknown): e is Required<Event> =>
  Boolean(e && typeof e === 'object' && 'preventDefault' in (e as Event));

export const useBoolState = (
  defaultValue = false,
  options?: { onFalse?: VoidFunction; preventDefault?: boolean },
) => {
  const onFalse = options?.onFalse;
  const shouldPreventDefault = options?.preventDefault ?? true;

  const [value, setValue] = React.useState(defaultValue);

  const handleToggle = React.useCallback(
    (e?: Event) => {
      if (shouldPreventDefault && isEvent(e)) {
        e.preventDefault();
      }

      const nextValue = !value;
      if (!nextValue) {
        onFalse?.();
      }
      setValue(nextValue);
    },
    [value, onFalse, shouldPreventDefault],
  );

  const handleTrue = React.useCallback(
    (e?: unknown) => {
      if (shouldPreventDefault && isEvent(e)) {
        e.preventDefault();
      }

      setValue(true);
    },
    [shouldPreventDefault],
  );

  const handleFalse = React.useCallback(
    (e?: unknown) => {
      if (shouldPreventDefault && isEvent(e)) {
        e.preventDefault();
      }
      onFalse?.();
      setValue(false);
    },
    [onFalse, shouldPreventDefault],
  );

  return React.useMemo(
    () => ({
      value: value,
      handleToggle,
      handleTrue,
      handleFalse,
      set: setValue,
      modalProps: {
        open: value,
        onOpenChange: setValue,
      },
    }),
    [handleFalse, handleToggle, handleTrue, value],
  );
};

// eslint-disable-next-line import/no-unused-modules
export type UseBoolStateReturn = ReturnType<typeof useBoolState>;
